/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { useArchiveData, useEase } from "hooks";
import { SEO } from "components";
import { Link } from "gatsby";
import styles from "./archive.module.scss";

const AppearancesArchivePage: React.FC = () => {
  const { archiveData, archiveYears } = useArchiveData();

  const appearancesData = {};

  archiveYears.forEach((year) => {
    const appearances = archiveData[year].filter(
      (item) => item.type === "appearance"
    );

    if (appearances.length) {
      appearancesData[year] = appearances;
    }
  });

  const appearanceYears = Object.keys(appearancesData).reverse();

  const { outQuad } = useEase();
  const getDelay = (a: number, b: number): string =>
    `${150 + 400 * outQuad(a) + 125 * outQuad(b)}ms`;

  return (
    <main className={styles.main}>
      <SEO title="Appearances" />
      <ol className={styles.yearList}>
        {appearanceYears.map((year, yearIndex) => (
          <li key={year} className={styles.yearListItem}>
            <h1 className={styles.yearLabel}>{year}</h1>
            <ol className={styles.archiveList}>
              {appearancesData[year].map((archiveItem, itemIndex) => (
                <li
                  key={archiveItem.id}
                  className={styles.archiveListItem}
                  style={{
                    animationDelay: getDelay(
                      yearIndex / appearanceYears.length,
                      itemIndex / appearancesData[year].length
                    ),
                  }}
                >
                  <p className={styles.itemType}>{archiveItem.role}</p>
                  {archiveItem.presentation?.file.asset.url ? (
                    <a
                      href={archiveItem.presentation.file.asset.url}
                      className={styles.itemLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h2 className={styles.itemTitle}>
                        {archiveItem.information!.title}
                      </h2>
                    </a>
                  ) : (
                    <h2 className={styles.itemTitle}>
                      {archiveItem.information!.title}
                    </h2>
                  )}
                  <p className={styles.itemDescription}>
                    {archiveItem.location.name}
                  </p>
                  {archiveItem.information!.tags && (
                    <ul className={styles.tagList}>
                      {archiveItem.information!.tags.map((tag) => (
                        <li
                          key={`${archiveItem.id}-${tag!.slug!.current}`}
                          className={styles.tagListItem}
                        >
                          {tag!.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ol>
          </li>
        ))}
      </ol>
    </main>
  );
};

export default AppearancesArchivePage;
